.CERA-PageHeader__view__2FA3g {
  font-family: 'proxima-nova', sans-serif; }
  .CERA-PageHeader__view-header__3RxCv {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row; }
    .CERA-PageHeader__view-header-title__3SOed {
      font-size: 24px;
      line-height: 28px;
      padding-left: 18px; }

.CERA-PageHeader__logo__2VPTc {
  height: 24px;
  left: 24px;
  top: 24px; }
