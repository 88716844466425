.CERA-LoginCard__login-card__9VBVk {
  background-color: #FFF;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  font-family: 'proxima-nova', sans-serif;
  height: 240px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 24px;
  text-align: center;
  width: 480px; }
  .CERA-LoginCard__login-card-button__1CkF6 {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    border: 1px solid #000;
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 8px;
    width: 240px; }
    .CERA-LoginCard__login-card-button-icon__HAsgz {
      height: 18px;
      width: 18px; }
    .CERA-LoginCard__login-card-button-row__T4H_k {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      padding: 24px 0; }
    .CERA-LoginCard__login-card-button-text__cwzbL {
      font-family: 'proxima-nova', sans-serif;
      font-size: 16px;
      margin-left: 24px; }
  .CERA-LoginCard__login-card-header__26I1l {
    font-size: 28px;
    margin-top: 24px; }
  .CERA-LoginCard__login-card-text__1By3_ {
    font-size: 16px; }
