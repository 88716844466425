.CERA-Portal__view__2bJPt {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  background-color: #FFF;
  border-radius: 3px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  font-family: "futura-pt", sans-serif;
  outline: none; }

.CERA-Portal__frame__NF8u1 {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%; }
