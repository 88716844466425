.CERA-Authorizer__view__1WVxa {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }

.CERA-Authorizer__background__35hSP {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%; }
