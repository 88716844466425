html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%; }

body {
  height: 100%;
  line-height: 1.42857143;
  padding: 0;
  width: 100%; }
  body *, body *:after, body *:before {
    -webkit-box-sizing: border-box;
            box-sizing: border-box; }

.MuiAppBar-positionFixed {
  top: inherit !important; }

#cover {
  display: none; }

a {
  color: #000;
  text-decoration: none; }
