.CERA-Login__primary-button__Eb8Kr {
  color: #ED1C24;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  font-family: 'proxima-nova', sans-serif;
  font-size: 18px;
  font-weight: 500;
  height: 48px;
  letter-spacing: 2px;
  position: relative; }

.CERA-Login__view__2IG_S {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }

.CERA-Login__logo__3lnKf {
  height: 24px;
  left: 48px;
  position: absolute;
  top: 48px; }

.CERA-Login__footer__UMpFa {
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  bottom: 48px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: absolute;
  right: 48px; }
  .CERA-Login__footer-text__2rma4 {
    color: #FFF;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    font-family: 'proxima-nova', sans-serif;
    font-size: 14px;
    position: relative;
    text-align: right; }

.CERA-Login__login__3yNSW {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-bottom: 48px;
  position: relative; }
