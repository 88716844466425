.CERA-RouterDisplay__router-display__1Opxg {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row; }
  .CERA-RouterDisplay__router-display-authenticated__1R0mH {
    background-color: #f7f8f9;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    height: 100%;
    overflow: hidden;
    padding: 0 0 24px 0;
    position: relative; }
    .CERA-RouterDisplay__router-display-authenticated-content__1oxE6 {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-flex: 1;
          -ms-flex: 1 1 auto;
              flex: 1 1 auto;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      height: 100%;
      position: relative;
      width: 100%;
      z-index: 5; }
    .CERA-RouterDisplay__router-display-authenticated-header__1IZmS {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column; }
    .CERA-RouterDisplay__router-display-authenticated-nav__3ey5l {
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      background-color: #fff;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-flex: 0;
          -ms-flex: 0 0 auto;
              flex: 0 0 auto;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
      height: 64px;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
      padding: 0 12px;
      position: relative;
      width: 100%;
      z-index: 6; }
  .CERA-RouterDisplay__router-display-view__2AEgI {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    overflow: hidden;
    z-index: 6; }
