.CERA-ErrorPage__action-button__1hjeZ {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 100%;
  letter-spacing: 1px;
  margin-left: 30px;
  padding: 0 16px;
  position: relative; }
  .CERA-ErrorPage__action-button-wrapper__RjDo6 {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    display: flex;
    font-family: "futura-pt", sans-serif;
    height: 36px;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    margin-top: 60px; }

.CERA-ErrorPage__view__18MLW {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: 100%; }
