.CERA-Splash__view__1rkcj {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }

.CERA-Splash__background__3UVJd {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%; }
