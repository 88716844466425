.CERA-Navbar__logo__2H9p7 {
  -ms-flex-item-align: center;
      align-self: center;
  height: 32px !important; }

.CERA-Navbar__icon__2WxwZ {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column; }
  .CERA-Navbar__icon-text__nwoai {
    cursor: pointer;
    margin-top: -4px; }
